import Iframes from './iframes';

export const thumbnails = {
    jmj: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/JMJ_TV_f7f0a2c509.png",
    jmj2: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/JMJ_TV_f7f0a2c509.png",
    comercio: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/PRACA_DO_COMERCIO_04dd2cca19.png",
    alameda: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/ALAMEDA_ea510eac7c.png",
    loures: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/LOURES_e112ac6e8f.png",
}

export const riseup = {
    pt: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/riseup_pt_6f551babb1.png",
    en: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/riseup_en_1ba97ba29c.png",
    it: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/riseup_it_38bcf09718.png",
    fr: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/riseup_fr_34d533185b.png",
    es: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/riseup_es_5ece096fe9.png",
}
export const riseupURL = {
    pt: "https://www.facebook.com/jornadamundialdajuventude",
    en: "https://www.facebook.com/worldyouthday",
    it: "https://www.facebook.com/giornatamondialedellagioventu",
    fr: "https://www.facebook.com/journeesmondialesdelajeunesse",
    es: "https://www.facebook.com/jornadamundialdelajuventud",
}

export const pt = {
    jmj: {
        iframe: "jmj",
        name: "JMJ TV",
        url: "transmissoes-online",
        desc: "",
    },
    jmj2: {
        iframe: "jmj2",
        name: "JMJ TV",
        url: "transmissoes-online-alternativa-jmj",
        desc: "",
    },
    comercio: {
        iframe: "comercio",
        name: "Praça do Comércio",
        url: "transmissoes-online-praca-do-comercio",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
    alameda: {
        iframe: "alameda",
        name: "Alameda",
        url: "transmissoes-online-alameda",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
    loures: {
        iframe: "loures",
        name: "Loures",
        url: "transmissoes-online-loures",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
}
export const en = {
    jmj: {
        iframe: "jmj_en",
        name: "JMJ TV",
        url: "online-streaming",
        desc: "",
    },
    jmj2: {
        iframe: "jmj2",
        name: "JMJ TV",
        url: "online-streaming-alternative",
        desc: "",
    },
    comercio: {
        iframe: "comercio",
        name: "Praça do Comércio",
        url: "online-streaming-praca-do-comercio",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
    alameda: {
        iframe: "alameda",
        name: "Alameda",
        url: "online-streaming-alameda",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
    loures: {
        iframe: "loures",
        name: "Loures",
        url: "online-streaming-loures",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
}
export const it = {
    jmj: {
        iframe: "jmj_it",
        name: "JMJ TV",
        url: "streaming-diretta",
        desc: "",
    },
    jmj2: {
        iframe: "jmj2",
        name: "JMJ TV",
        url: "jmj-alt",
        desc: "",
    },
    comercio: {
        iframe: "comercio",
        name: "Praça do Comércio",
        url: "streaming-diretta-praca-do-comercio",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
    alameda: {
        iframe: "alameda",
        name: "Alameda",
        url: "streaming-diretta-alameda",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
    loures: {
        iframe: "loures",
        name: "Loures",
        url: "streaming-diretta-loures",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
}
export const fr = {
    jmj: {
        iframe: "jmj_fr",
        name: "JMJ TV",
        url: "streaming-ligne",
        desc: "",
    },
    jmj2: {
        iframe: "jmj2",
        name: "JMJ TV",
        url: "streaming-ligne-alt",
        desc: "",
    },
    comercio: {
        iframe: "comercio",
        name: "Praça do Comércio",
        url: "streaming-ligne-praca-do-comercio",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
    alameda: {
        iframe: "alameda",
        name: "Alameda",
        url: "streaming-ligne-alameda",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
    loures: {
        iframe: "loures",
        name: "Loures",
        url: "streaming-ligne-loures",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
}
export const es = {
    jmj: {
        iframe: "jmj_es",
        name: "JMJ TV",
        url: "transmisiones-vivo",
        desc: "",
    },
    jmj2: {
        iframe: "jmj2",
        name: "JMJ TV",
        url: "transmisiones-vivo-alt",
        desc: "",
    },
    comercio: {
        iframe: "comercio",
        name: "Praça do Comércio",
        url: "transmisiones-vivo-praca-do-comercio",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
    alameda: {
        iframe: "alameda",
        name: "Alameda",
        url: "transmisiones-vivo-alameda",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
    loures: {
        iframe: "loures",
        name: "Loures",
        url: "transmisiones-vivo-loures",
        desc: "Aqui pode ver eventos do Festival da Juventude",
    },
}


export default {
    pt: pt,
    en: en,
    it: it,
    fr: fr,
    es: es,
}