import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container, Form } from "react-bootstrap";
import { read, utils } from "xlsx";
import Copy from "../../Copy";
import Table from './table';
const tableCss = `
.nutritional-table-holder {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  display:block;
  max-width: 100%;
}
table.nutrition-table td {
  text-align: center;
  padding: 5px;
  font-size: 14px;
}
`;
const XLSXToHTMLTable = () => {
  const [tableHTML, setTableHTML] = useState("")
  const [preparedHtml, setPreparedHtml] = useState("")

  useEffect(() => {
    const html = `
      <style>
      ${tableCss}
      </style>
      ${tableHTML}
    `;
    setPreparedHtml(html);
  }, [tableHTML]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "binary", cellStyles: true });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      generateHTMLTable(sheet, workbook);
    };

    reader.readAsBinaryString(file);
  };
  const generateHTMLTable = (sheet, workbook) => {
    const htmlRows = [];
    const range = utils.decode_range(sheet["!ref"]);
    const merges = sheet["!merges"] || [];

    for (let row = range.s.r; row <= range.e.r; row++) {
      const htmlCells = [];
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cellAddress = utils.encode_cell({ r: row, c: col });
        const cell = sheet[cellAddress];

        const style = cell && cell.s ? getCellStyle(cell.s, workbook) : "";

        if (!cell && !isCellMerged(cellAddress, merges)) {
          const htmlCell = `<td style="${style}">&nbsp;</td>`;
          htmlCells.push(htmlCell);
        } else if (!cell || isCellMerged(cellAddress, merges)) {
          if (!isFirstCellMerged(cellAddress, merges)) {
            // Skip additional cells in a merged range
            continue;
          }

          const cellValue = cell && cell.v ? cell.v : "&nbsp;";
          const [colspan, rowspan] = getCellSpan(cellAddress, merges);
          const htmlCell = `<td colspan="${colspan}" class="table-title" rowspan="${rowspan}" style="${style}">${cellValue}</td>`;
          htmlCells.push(htmlCell);
        } else {
          const cellValue = cell && cell.v ? cell.v : "&nbsp;";
          const htmlCell = `<td style="${style}">${cellValue}</td>`;
          htmlCells.push(htmlCell);
        }
      }
      const htmlRow = `<tr>${htmlCells.join("")}</tr>`;
      htmlRows.push(htmlRow);
    }

    setTableHTML(
      `<div class="nutritional-table-holder"><table class="nutrition-table">${htmlRows.join(
        ""
      )}</table></div>`
    );
  };

  const isFirstCellMerged = (cellAddress, merges) => {
    const decodedCellAddress = utils.decode_cell(cellAddress);
    return merges.some((merge) => {
      return (
        decodedCellAddress.r === merge.s.r && decodedCellAddress.c === merge.s.c
      );
    });
  };


  const isCellMerged = (cellAddress, merges) => {
    const decodedCellAddress = utils.decode_cell(cellAddress);

    return merges.some((merge) => {
      return (
        decodedCellAddress.r >= merge.s.r &&
        decodedCellAddress.r <= merge.e.r &&
        decodedCellAddress.c >= merge.s.c &&
        decodedCellAddress.c <= merge.e.c
      );
    });
  };

 const getCellSpan = (cellAddress, merges) => {
   const decodedCellAddress = utils.decode_cell(cellAddress);
   const merge = merges.find((merge) => {
     return (
       decodedCellAddress.r >= merge.s.r &&
       decodedCellAddress.r <= merge.e.r &&
       decodedCellAddress.c >= merge.s.c &&
       decodedCellAddress.c <= merge.e.c
     );
   });

   if (merge) {
     return [merge.e.c - merge.s.c + 1, merge.e.r - merge.s.r + 1];
   } else {
     return [1, 1];
   }
 };

  const getCellStyle = (cellStyle, workbook) => {
    if (!cellStyle) return "";

    const colors = [
      {
        original: "FFFF00",
        newColor: "FAC112",
      },
      {
        original: "A9D08E",
        newColor: "00873e",
      },
      {
        original: "A9D18E",
        newColor: "00873e",
      },
      {
        original: "7030A0",
        newColor: "BFBFBF",
      },
    ];

    let backgroundColor = cellStyle.fgColor?.rgb;
    for (let i = 0; i < colors.length; i++) {
      if (colors[i].original === backgroundColor) {
        backgroundColor = colors[i].newColor
      }
    }


    const background = backgroundColor ? `#${backgroundColor}` : "transparent";

    const bold = background !== "transparent" ? "bold" : "normal";
    const color = backgroundColor === "00873e" ? "#FFF" : null;
    const styleProps = {
      background: background,
      "font-weight": bold,
    };

    if (color) {
      styleProps.color = color;
    }

    return Object.entries(styleProps)
      .filter(([prop, value]) => value !== "inherit")
      .map(([prop, value]) => `${prop}: ${value}`)
      .join(";");
  };

  return (
    <div>
      <Container>
        <Form.Group controlId="formFile" className="mb-3 mt-3">
          <Form.Control type="file" onChange={handleFileChange} />
        </Form.Group>
        <div className="mb-5">
          HTML: <Copy text={preparedHtml} />
        </div>
        <Table tableHTML={tableHTML} />
      </Container>
    </div>
  );
};

export default XLSXToHTMLTable;
