import React from "react";
import categoriesDefinitions from "./categories";
import schema from "./schema";

export const DayTemplate = ({ item, locale }) => {
  console.log(item);
  const categories = Object.keys(item.categories);
  const keys = schema[locale]
  const defaults = schema.pt

  return (
    <div className="schedule-container" data-date={item.date} data-lang={locale}>
      {categoriesDefinitions.map((categoryDef) => {
        const categName = categoryDef.category;
        if (!categories.includes(categName)) {
          return null;
        }
        const categoryEvents = item.categories[categName]?.items;

        return (
          <div className="category-block">
            <div className="category-holder">
              <div className="category-title">
                {categoryDef.icon !== "" && (
                  <>
                    <span
                      className={`category-icon ${categoryDef.class}`}
                    ></span>{" "}
                  </>
                )}
                <span>{categoryDef.translations[locale]}</span>
              </div>
            </div>
            <div className="events">
              <div className="category-title">
                {categoryDef.icon !== "" && (
                  <>
                    <span
                      className={`category-icon ${categoryDef.class}`}
                    ></span>{" "}
                  </>
                )}
                <span>{categoryDef.translations[locale]}</span>
              </div>
              {categoryEvents.map((evt) => (
                <div className="event-details">
                  <div className="event-name">
                    {evt[keys.name] || evt[defaults.name]}
                  </div>
                  {evt[keys.subtitle] && (
                    <div className="event-subtitle">{evt[keys.subtitle]}</div>
                  )}
                  {evt[keys.link] && (
                    <div className="event-link">
                      <a href={evt[keys.link]} target="_blank" rel="noreferrer">
                        {keys.readmore}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                        >
                          <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                        </svg>
                      </a>
                    </div>
                  )}
                  <div className="event-time">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                    >
                      <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
                    </svg>{" "}
                    {evt[keys.start_time]}{" "}
                    {evt[keys.end_time] && <> - {evt[keys.end_time]}</>}
                  </div>
                  <div className="event-location">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 384 512"
                    >
                      <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                    </svg>{" "}
                    {evt[keys.location] === evt[keys.room] && (
                      <>{evt[keys.location]}</>
                    )}
                    {evt[keys.location] !== evt[keys.room] && (
                      <>
                        {evt[keys.location]} - {evt[keys.room]}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DayTemplate;
