import React, { useEffect, useState } from "react";

export const JMJ = ({ youtubeId }) => (
  <div style={{ position: "relative", display: "block", maxWidth: "100%" }}>
    <div style={{ paddingTop: "56.25%" }}>
      <iframe
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title="JMJ TV"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen="true"
      ></iframe>
    </div>
  </div>
);

export const JMJ_EN = ({ youtubeId }) => (
  <div style={{ position: "relative", display: "block", maxWidth: "100%" }}>
    <div style={{ paddingTop: "56.25%" }}>
      <iframe
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title="JMJ TV"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
);

export const JMJ_IT = ({ youtubeId }) => (
  <div style={{ position: "relative", display: "block", maxWidth: "100%" }}>
    <div style={{ paddingTop: "56.25%" }}>
      <iframe
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title="JMJ TV"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
);

export const JMJ_FR = ({ youtubeId }) => (
  <div style={{ position: "relative", display: "block", maxWidth: "100%" }}>
    <div style={{ paddingTop: "56.25%" }}>
      <iframe
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title="JMJ TV"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
);

export const JMJ_ES = ({ youtubeId }) => (
  <div style={{ position: "relative", display: "block", maxWidth: "100%" }}>
    <div style={{ paddingTop: "56.25%" }}>
      <iframe
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px",
          width: "100%",
          height: "100%",
        }}
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title="JMJ TV"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  </div>
);

export const JMJ2 = () => (
  <div style={{position: "relative", display: "block", maxWidth: "100%"}}>
    <div style={{paddingTop: "56.25%"}}>
      <iframe
        src="https://players.brightcove.net/6236382001001/FeOe1KzgN_default/index.html?videoId=6331882137112"
        allowfullscreen=""
        allow="encrypted-media"
        style={{position: "absolute", top: "0px", right: "0px", bottom: "0px", left: "0px", width: "100%", height: "100%"}}
      ></iframe>
    </div>
  </div>
);

export const Comercio = () => (
  <div style={{position: "relative", display: "block", maxWidth: "100%"}}>
    <div style={{paddingTop: "56.25%"}}>
      <iframe
        src="https://players.brightcove.net/6236382001001/FeOe1KzgN_default/index.html?videoId=6331882446112"
        allowfullscreen=""
        allow="encrypted-media"
        style={{position: "absolute", top: "0px", right: "0px", bottom: "0px", left: "0px", width: "100%", height: "100%"}}
      ></iframe>
    </div>
  </div>
);

export const Alameda = () => (
  <div style={{position: "relative", display: "block", maxWidth: "100%"}}>
    <div style={{paddingTop: "56.25%"}}>
      <iframe
        src="https://players.brightcove.net/6236382001001/FeOe1KzgN_default/index.html?videoId=6331883131112"
        allowfullscreen=""
        allow="encrypted-media"
        style={{position: "absolute", top: "0px", right: "0px", bottom: "0px", left: "0px", width: "100%", height: "100%"}}
      ></iframe>
    </div>
  </div>
);

export const Loures = () => (
  <div style={{position: "relative", display: "block", maxWidth: "100%"}}>
    <div style={{paddingTop: "56.25%"}}>
      <iframe
        src="https://players.brightcove.net/6236382001001/FeOe1KzgN_default/index.html?videoId=6331882356112"
        allowfullscreen=""
        allow="encrypted-media"
        style={{position: "absolute", top: "0px", right: "0px", bottom: "0px", left: "0px", width: "100%", height: "100%"}}
      ></iframe>
    </div>
  </div>
);

export default {
  Alameda: Alameda,
  JMJ: JMJ,
  JMJ2: JMJ2,
  Comercio: Comercio,
  Loures: Loures,
};
