const categories = [
  {
    "category": "Eventos Religiosos e de Encontro",
    "icon": "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/religious_events_9d234942a8.png",
    "class": "religious",
    translations: {
      pt: "Eventos Religiosos e de Encontro",
      en: "Religious and Encounter Events",
      it: "Eventi religiosi e di incontro",
      fr: "Événements religieux et de rencontre",
      es: "Eventos Religiosos y de Encuentro",
    }
  },
  {
    "category": "Música",
    "icon": "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/music_ffd0dc8d58.png",
    "class": "music",
    translations: {
      pt: "Música",
      en: "Music",
      it: "Musica",
      fr: "Musique",
      es: "Musica",
    }
  },

  {
    "category": "Teatro e Dança",
    "icon": "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/dancing_ecf0de3626.png",
    "class": "dance",
    translations: {
      pt: "Teatro e Dança",
      en: "Theatre and Dance",
      it: "Teatro e danza",
      fr: "Théâtre et danse",
      es: "Teatro y danza",
    }
  },
  {
    "category": "Cinema",
    "icon": "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/cinema_0b9d13c743.png",
    "class": "cinema",
    translations: {
      pt: "Cinema",
      en: "Movies",
      it: "Film",
      fr: "Films",
      es: "Películas",
    }
  },
  {
    "category": "Conferências",
    "icon": "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/conferences_3ff9cb7f9d.png",
    "class": "conferences",
    translations: {
      pt: "Conferências",
      en: "Conferences",
      it: "Conferenze",
      fr: "Conférences",
      es: "Conferencias",
    }
  },
  {
    "category": "Exposições",
    "icon": "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/expos_c0226fc2e3.png",
    "class": "expos",
    translations: {
      pt: "Exposições",
      en: "Exhibitions",
      it: "Mostre",
      fr: "Expositions",
      es: "Exposiciones",
    }
  },
  {
    "category": "Patronos JMJ",
    "icon": "",
    "class": "",
    translations: {
      pt: "Patronos JMJ",
      en: "Patron Saint WYD",
      it: "Patroni GMG",
      fr: "Patrons de JMJ",
      es: "Patronos JMJ",
    }
  },
  {
    "category": "JMJ Sports",
    "icon": "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/sports_7f9c263989.png",
    "class": "sports",
    translations: {
      pt: "JMJ Sports",
      en: "JMJ Sports",
      it: "JMJ Sports",
      fr: "JMJ Sports",
      es: "JMJ Sports",
    }
  },
  {
    "category": "All Around",
    "icon": "",
    "class": "",
    translations: {
      pt: "All Around",
      en: "All Around",
      it: "All Around",
      fr: "All Around",
      es: "All Around",
    }
  },
  {
    "category": "Museus",
    "icon": "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/museus_4cf3862500.png",
    "class": "museums",
    translations: {
      pt: "Museus",
      en: "Museums",
      it: "I musei",
      fr: "Musées",
      es: "Museos",
    }
  }
];

export default categories;