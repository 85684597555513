import React, { useEffect, useState } from "react";


const Template = ({ items, locale }) => {
  const locationTranslation = (location) => {
    const translations = {
      Todos: {
        pt: "Todos",
        en: "All restaurants",
        fr: "Tous les restaurants",
        es: "Todos los restaurantes",
        it: "Tutti i ristoranti",
      }
    }

    if(Object.keys(translations).includes(location)) {
      return translations[location][locale];
    }
    return location;
  }
  return (
    <>
      <div className="restaurants">
        <div className="restaurants-index">
          <ul className="restaurants-index-list">
            {items.letters.map((letter) => (
              <li>
                <a href={`#restaurants-${letter}`}>{letter}</a>
              </li>
            ))}
          </ul>

          <div class="restaurants-list">
            {items.letters.map((letter) => (
              <>
                <span id={`restaurants-${letter}`}>&nbsp;</span>
                <h3>{letter}</h3>
                <div className="restaurants-list-contents">
                  {items.restaurants[letter].map((item) => (
                    <div className="restaurant">
                      {item.name} |{" "}
                      <a
                        href={`https://maps.google.com/?q=${item.searchTerm}`}
                        target="_blank" rel="noreferrer"
                      >
                        {locationTranslation(item.location)}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Template;