import React, { useEffect, useState } from "react";
import Copy from "../../Copy";
import { Col, Container, Row, Form } from "react-bootstrap";
import { renderToStaticMarkup } from "react-dom/server";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import Template from "./template";
import CSVReader from "../../CsvReader";

const Partituras = () => {
  const [preparedData, setPreparedData] = useState({restaurants: {}, letters: []});
  const [preparedDataHTML, setPreparedDataHTML] = useState({});
  const [styles, setStyles] = useState();
  const [locale, setLocale] = useState("pt");

  useEffect(() => {
    fetch("/css-restaurants.css.txt")
      .then((r) => r.text())
      .then((cssString) => {
        const styleEl = document.createElement("style");
        styleEl.innerHTML = cssString;
        setStyles("<style>" + cssString + "</style>");
        document.head.appendChild(styleEl);
        return () => {
          document.head.removeChild(styleEl);
        };
      });
  }, []);

  const getHTML = (data) => {
    const htmlString = renderToStaticMarkup(
      <Template items={data} locale={locale} />
    );
    return htmlString;
  };

  const prepareData = (data) => {
    const prepared = { restaurants: {}, letters: [] };
    const letters = []
    console.log(data)
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const restaurant = item.RESTAURANTE;
      const location = item.LOCALIZAÇÃO;
      const firstLetter = restaurant?.charAt(0).toUpperCase();
      if (restaurant) {
        if (!letters.includes(firstLetter)) {
          letters.push(firstLetter)
        }
        if (typeof prepared.restaurants[firstLetter] === "undefined") {
          prepared.restaurants[firstLetter] = [];
        }
        const searchTerm = location === "Todos" || location === "" ? restaurant : `${restaurant}, ${location}`
        prepared.restaurants[firstLetter].push({name: restaurant, location: location, searchTerm: searchTerm});
      }
    }

    prepared.letters = letters.sort()

    return prepared;
  };

  useEffect(() => {
    const htmlData = getHTML(preparedData);
    setPreparedDataHTML(htmlData);
  }, [preparedData, locale]);

  return (
    <Container className="mt-5 mb-5">
      <Row className="mb-5">
        <Col md="6">
          <CSVReader
            onUploadAccepted={(results) => {
              setPreparedData({});
              const d = prepareData(results.data);
              setPreparedData(d);
            }}
          ></CSVReader>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Idioma</Form.Label>
            <Form.Select
              value={locale}
              onChange={(e) => setLocale(e.target.value)}
              aria-label="Default select example"
            >
              <option value="pt">pt</option>
              <option value="en">en</option>
              <option value="es">es</option>
              <option value="fr">fr</option>
              <option value="it">it</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <div>
        <div className="mb-3">
          CSS: <Copy text={styles} />
        </div>
        <div className="mb-3">
          HTML: <Copy text={preparedDataHTML} />
        </div>
      </div>

      <Template items={preparedData} locale={locale} />
    </Container>
  );
};

export default Partituras;
