import React from "react";

const Table = ({ tableHTML }) => {
  return (
    <div className="nutritional-table-holder">
      <div dangerouslySetInnerHTML={{ __html: tableHTML }}></div>
    </div>
  );
};

export default Table;
