import React, { useEffect, useState } from "react";
import Copy from "../../Copy";
import { Col, Container, Row, Form } from "react-bootstrap";
import { renderToStaticMarkup } from "react-dom/server";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import Template from "./template";
import CSVReader from "../../CsvReader";

const Riseup = () => {
  const [preparedData, setPreparedData] = useState({ riseup: {}, letters: [] });
  const [preparedDataHTML, setPreparedDataHTML] = useState({});
  const [styles, setStyles] = useState();
  const [locale, setLocale] = useState("pt");
  const languages = ["pt", "en", "it", "fr", "es"];

  const columns = {
    location: 15,
    desc_pt: 23,
    desc_en: 24,
    desc_fr: 25,
    desc_es: 26,
    desc_it: 27,
    lat: 34,
    long: 35,
    language: 29,
  };

  useEffect(() => {
    fetch("/style-riseup.css.txt")
      .then((r) => r.text())
      .then((cssString) => {
        const styleEl = document.createElement("style");
        styleEl.innerHTML = cssString;
        setStyles("<style>" + cssString + "</style>");
        document.head.appendChild(styleEl);
        return () => {
          document.head.removeChild(styleEl);
        };
      });
  }, []);

  const getHTML = (data, loc) => {
    const htmlString = renderToStaticMarkup(
      <Template items={data} locale={loc} />
    );
    return htmlString;
  };

  const prepareData = (data) => {
    console.log(data);
    const prepared = { riseup: {}, letters: [] };
    const letters = [];
    if (data.length > 1) {
      data.shift();
    }
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const location = item[columns.location];
      const desc_pt = item[columns.desc_pt];
      const desc_en = item[columns.desc_en];
      const desc_es = item[columns.desc_es];
      const desc_it = item[columns.desc_it];
      const desc_fr = item[columns.desc_fr];
      const lat = item[columns.lat];
      const long = item[columns.long];
      const language = item[columns.language];

      if (location) {
        if (!letters.includes(language)) {
          letters.push(language);
        }
        if (typeof prepared.riseup[language] === "undefined") {
          prepared.riseup[language] = [];
        }
        // const searchTerm = location === "Todos" || location === "" ? restaurant : `${restaurant}, ${location}`
        const searchTerm = `${lat},${long}`;
        prepared.riseup[language].push({
          name: location,
          desc: {
            pt: desc_pt,
            en: desc_en,
            es: desc_es,
            it: desc_it,
            fr: desc_fr,
          },
          language: language,
          searchTerm: searchTerm,
        });
      }
    }

    prepared.letters = letters.sort();

    return prepared;
  };

  useEffect(() => {
    const prepared = {};
    languages.forEach((l) => {
      prepared[l] = getHTML(preparedData, l);
    });
    setPreparedDataHTML(prepared);
  }, [preparedData]);

  return (
    <Container className="mt-5 mb-5">
      <Row className="mb-5">
        <Col md="6">
          <CSVReader
            useHeaders={false}
            onUploadAccepted={(results) => {
              setPreparedData({});
              const d = prepareData(results.data);
              setPreparedData(d);
            }}
          ></CSVReader>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <Form.Label>Idioma</Form.Label>
            <Form.Select
              value={locale}
              onChange={(e) => setLocale(e.target.value)}
              aria-label="Default select example"
            >
              <option value="pt">pt</option>
              <option value="en">en</option>
              <option value="es">es</option>
              <option value="fr">fr</option>
              <option value="it">it</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <div>
        <div className="mb-3">
          CSS: <Copy text={styles} />
        </div>
        <Row>
          {languages.map((l) => (
            <Col>
              <h5>{l}</h5>
              <div className="mb-3">
                HTML: <Copy text={preparedDataHTML[l]} />
              </div>
            </Col>
          ))}
        </Row>
      </div>

      <Template items={preparedData} locale={locale} />
    </Container>
  );
};

export default Riseup;
