import React, {useState, useEffect } from "react";
import Copy from "../../Copy";
import { Col, Container, Row, Form } from "react-bootstrap";
import { renderToStaticMarkup } from "react-dom/server";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import data from "./data";

import Template from "./template";

function Transmissions() {
    const channels = ["jmj", "alameda", "comercio", "loures" , "jmj2"];
    const languages = ["pt", "en", "it", "fr", "es"];
    const [loaded, setLoaded] = useState(false)
    const [preparedData, setPreparedData] = useState({});
    const [preview, setPreview] = useState("jmj")
    const [locale, setLocale] = useState("pt")
    const [styles, setStyles] = useState("");
    const [youtubeURLs, setYoutubeURLs] = useState({
      pt: "",
      en: "",
      it: "",
      fr: "",
      es: "",
    });
    const [youtubeIds, setYoutubeIds] = useState({
      pt: "",
      en: "",
      it: "",
      fr: "",
      es: "",
    });
    const [allIds, setAllIds] = useState(false)

    const setYouTubeID = (key, value) => {
      const new_yt_urls = { ...youtubeURLs, [key]: value };
      const ytid = YouTubeGetID(value);
      const new_yt = { ...youtubeIds, [key]: ytid };
      setYoutubeURLs(new_yt_urls);
      setYoutubeIds(new_yt);
    }

    useEffect(() => {
      const keys = Object.keys(youtubeIds)
      let allOk = true
      keys.forEach(k => {
        if (youtubeIds[k] === "") {
          allOk = false
        }
      })

      setAllIds(allOk)
    }, [youtubeIds])

    const YouTubeGetID = (url) => {
      url = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return undefined !== url[2] ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
    }


    const getHTML = (main, others, locale, alternative) => {
        const htmlString = renderToStaticMarkup(
          <Template main={main} others={others} locale={locale} alternative={alternative} youtube={youtubeIds} />
        );
        return htmlString;
    };

  const getData = () => {
    const prepared = {};
    languages.map(l => {
        prepared[l] = {}
        channels.forEach(c => {
            const main = data[l][c]
            const alternative = c == "jmj" ? data[l].jmj2.url : data[l].jmj.url;
            const others = channels.filter(i => i !== c && i !== "jmj2" && !(c === "jmj2" && i === "jmj")).map(item => {
                return {...data[l][item], id: item}
            })
            prepared[l][c] = getHTML(main, others, l, alternative);
        })
    })
    setPreparedData(prepared)
    setLoaded(true)
  }

  useEffect(() => {
    fetch("/style-transmissoes.css.txt")
      .then((r) => r.text())
      .then((cssString) => {        
        setStyles(
          "<style>" + cssString + "\r\n" + "</style>"
        );
        const styleEl = document.createElement("style");
        styleEl.innerHTML = cssString + "\r\n";
        document.head.appendChild(styleEl);
        return () => {
          document.head.removeChild(styleEl);
        };
      });
      getData();
  }, []);

  useEffect(() => {getData();}, [youtubeIds]);

  return (
    <Container>
      <Form>
        <Row>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label>Youtube PT</Form.Label>
              <Form.Control
                value={youtubeURLs.pt}
                onChange={(e) => setYouTubeID("pt", e.target.value)}
                type="text"
                placeholder=""
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label>Youtube EN</Form.Label>
              <Form.Control
                value={youtubeURLs.en}
                onChange={(e) => setYouTubeID("en", e.target.value)}
                type="text"
                placeholder=""
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label>Youtube IT</Form.Label>
              <Form.Control
                value={youtubeURLs.it}
                onChange={(e) => setYouTubeID("it", e.target.value)}
                type="text"
                placeholder=""
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label>Youtube FR</Form.Label>
              <Form.Control
                value={youtubeURLs.fr}
                onChange={(e) => setYouTubeID("fr", e.target.value)}
                type="text"
                placeholder=""
              />
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group className="mb-3">
              <Form.Label>Youtube ES</Form.Label>
              <Form.Control
                value={youtubeURLs.es}
                onChange={(e) => setYouTubeID("es", e.target.value)}
                type="text"
                placeholder=""
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <div className="mb-3 mt-3">
        <Copy  text={styles} label="CSS" />
      </div>
      <Row className="mb-3">
        {loaded &&
          allIds &&
          languages.map((l) => (
            <>
              <Col>
                <h5>{l}</h5>

                {channels.map((c) => (
                  <>
                    <h6></h6>
                    <div>
                      <Copy label={c} text={preparedData[l][c]} />
                    </div>
                  </>
                ))}
              </Col>
            </>
          ))}
      </Row>
      {loaded && allIds && (
        <>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Idioma</Form.Label>
                <Form.Select
                  value={locale}
                  onChange={(e) => setLocale(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="pt">pt</option>
                  <option value="en">en</option>
                  <option value="es">es</option>
                  <option value="fr">fr</option>
                  <option value="it">it</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Idioma</Form.Label>
                <Form.Select
                  value={preview}
                  onChange={(e) => setPreview(e.target.value)}
                  aria-label="Default select example"
                >
                  <option value="jmj">jmj</option>
                  <option value="jmj2">jmj2</option>
                  <option value="alameda">alameda</option>
                  <option value="comercio">comercio</option>
                  <option value="loures">loures</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <div
            dangerouslySetInnerHTML={{ __html: preparedData[locale][preview] }}
          ></div>
        </>
      )}
    </Container>
  );
}

export default Transmissions;