import React from "react";
import {
    JMJ,
    JMJ2,
    Alameda,
    Comercio,
    Loures,
    JMJ_ES,
    JMJ_EN,
    JMJ_IT,
    JMJ_FR,
} from "./iframes";

import { thumbnails, riseup, riseupURL } from "./data";

const IframeComponent = (iframeName) => {
  const components = {
    jmj: JMJ,
    jmj2: JMJ2,
    alameda: Alameda,
    comercio: Comercio,
    loures: Loures,
    jmj_es: JMJ_ES,
    jmj_en: JMJ_EN,
    jmj_it: JMJ_IT,
    jmj_fr: JMJ_FR,
  };
  return components[iframeName];
};

const translations = {
  other: {
    pt: "Outras transmissões",
    en: "Other broadcasts",
    fr: "Autres émissions",
    it: "Altre trasmissioni",
    es: "Otras emisiones",
  },
  alternative: {
    pt: "Caso não consiga ver esta transmissão, clique aqui.",
    es: "Si no puede ver esta emisión, haga clic aquí.",
    en: "If you are unable to view this broadcast, click here.",
    fr: "Si vous ne pouvez pas visionner cette émission, veuillez cliquer ici.",
    it: "Se non riuscite a visualizzare questa trasmissione, cliccate qui.",
  },
  riseup: {
    pt: "Acompanha alguns dos Encontros Rise Up no nosso Facebook.",
    es: "Sigue algunas de las reuniones de Rise Up en nuestra página de Facebook.",
    en: "Follow some of the Rise Up Encounters in our Facebook page.",
    fr: "Suis plusieurs des Rencontres Rise Up sur notre page Facebook.",
    it: "Segui alcuni degli incontri di Rise Up sulla nostra pagina Facebook.",
  },
};

const Template = ({ main, others, locale, alternative, youtube }) => {
  const MyIframe = IframeComponent(main.iframe);
  console.log(others)
  return (
    <>
      <div class="transmissions-featured">
        <MyIframe youtubeId={youtube[locale]} />
      </div>
      {["jmj", "jmj2"].includes(main.iframe) && locale === "pt" && (
        <div class="alternative-transmission">
          <div class="alternative-thumb">
            <a
              href={`https://www.lisboa2023.org/pt/${alternative}`}
            >
              <img src={thumbnails.jmj} alt="JMJ TV" />
            </a>
          </div>
          <div class="alternative-link">
            <a href={`https://www.lisboa2023.org/pt/${alternative}`}>
              {translations.alternative[locale]}
            </a>
          </div>
        </div>
      )}
      <h5 class="other-transmissions-title">{translations.other[locale]}</h5>
      <div class="other-transmissions jmj-row">
        {others.map((item) => (
          <div class="transmission jmj-col">
            <div class="transmission-thumbnail">
              <a href={item.url}>
                <img src={thumbnails[item.id]} alt={item.name} />
              </a>
            </div>
            <div class="transmission-name">
              <a href={item.url}>{item.name}</a>
            </div>
          </div>
        ))}
      </div>

      <div class="alternative-transmission">
        <div class="alternative-thumb">
          <a href={riseupURL[locale]} target="_blank">
            <img src={riseup[locale]} alt="Rise Up" />
          </a>
        </div>
        <div class="alternative-link">
          <a href={riseupURL[locale]} target="_blank">
            {translations.riseup[locale]}
          </a>
        </div>
      </div>
    </>
  );
};


export default Template;