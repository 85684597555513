const guides = {
    pt: {
        file: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/PT_GUIA_DO_PEREGRINO_fd1cb88f16.pdf",
        thumb: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/guia_peregrino_pt_280085e906.png",
        name: "Guia do Peregrino"
    },
    en: {
        file: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/EN_PILGRIM_S_HANDBOOK_f8e26922ad.pdf",
        thumb: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/guia_peregrino_en_913112460c.png",
        name: "Pilgrim's Handbook"
    },
    es: {
        file: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/ES_GUIA_DEL_PEREGRINO_aa4bb427bd.pdf",
        thumb: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/guia_peregrino_es_8cfc238205.png",
        name: "Guía del Peregrino"
    },
    fr: {
        file: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/FR_GUIDE_DO_PELERIN_52aa6e2165.pdf",
        thumb: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/guia_peregrino_fr_vf_c01e9fa4e0.png",
        name: "Guide do Pèlerin"
    },
    it: {
        file: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/IT_GUIDA_DEL_PELLEGRINO_cd8c780e1e.pdf",
        thumb: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/guia_peregrino_it_cb6da99a1c.png",
        name: "Guida del Pellegrino"
    },
}

const prayerBook = {
    pt: {
        file: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/Livro_de_Oracoes_PT_5099cfd355.pdf",
        thumb: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/livro_oracoes_pt_04800f3405.png",
        name: "Up2Pray - Livro de orações"
    },
    en: {
        file: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/Prayer_Book_EN_8dedee393e.pdf",
        thumb: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/livro_oracoes_en_648e00b85f.png",
        name: "Up2Pray - Prayer's book"
    },
    es: {
        file: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/Libro_de_oraciones_ES_a5cfd41e2f.pdf",
        thumb: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/livro_oracoes_es_717b234a1a.png",
        name: "Up2Pray - Libro de oraciones"
    },
    fr: {
        file: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/Livre_de_priere_FR_a0148f9eea.pdf",
        thumb: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/livro_oracoes_fr_0b0b2b5ef7.png",
        name: "Up2Pray - Livre de prière"
    },
    it: {
        file: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/Libro_di_preghiere_IT_d5997f3f55.pdf",
        thumb: "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/livro_oracoes_it_ede49a3280.png",
        name: "Up2Pray - Libro di preghiere"
    },
}

const buttons = {
    pt: "Descarregar",
    en: "DOWNLOAD",
    it: "SCARICARE",
    fr: "TÉLÉCHARGER",
    es: "DESCARGAR",
}

const languages = {
    pt: "Português",
    en: "English",
    it: "Italiano",
    fr: "Français",
    es: "Español",
}

export default {
    buttons: buttons,
    guides: guides,
    prayerBook: prayerBook,
    languages: languages,
}