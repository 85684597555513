import React, { useEffect, useState } from "react";
import Copy from "../../Copy";
import { Col, Container, Row, Form } from "react-bootstrap";
import Template from "./template";
import { renderToStaticMarkup } from "react-dom/server";

const forces = {
  psp: {
    site: "https://www.psp.pt/Pages/homePage.aspx",
    phone: "21 765 42 42",
    name: "PSP",
    image:
      "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/psp_4ba9e262db.png",
    siteShort: "psp.pt",
  },
  gnr: {
    site: "https://www.gnr.pt",
    phone: "",
    name: "GNR",
    image:
      "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/gnr_frontcontent_1_ad7c2fed57.png",
    siteShort: "gnr.pt",
  },
  pj: {
    site: "https://www.pj.pt",
    phone: "211 967 000",
    name: "PJ",
    image:
      "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/PJ_logo_fundo_transparente_6944a3b9ca.png",
    siteShort: "www.pj.pt",
  },
  sns: {
    site: "https://www.sns24.gov.pt",
    phone: "808 24 24 24",
    name: "SNS 24",
    image:
      "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/sns24_manuel_9d001a4d5b.jpg",
    siteShort: "www.sns24.gov.pt",
  },
  inem: {
    site: "https://www.inem.pt/",
    phone: "",
    name: "INEM",
    image:
      "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/1_INEM_logo_rgb_pos_principal_74653246f3.png",
    siteShort: "www.inem.pt",
  },
  sef: {
    site: "https://www.sef.pt/pt/Pages/homepage.aspx",
    phone: "214 236 600",
    name: "SEF",
    image:
      "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/SEF_vectorial_pantone_cor_b4d167b361.png",
    siteShort: "sef.pt",
  },
  anepc: {
    site: "https://prociv.gov.pt/pt/home/",
    phone: "",
    name: "ANEPC",
    image:
      "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/ANEPC_abfe38418f.png",
    siteShort: "prociv.gov.pt",
  },
  amn: {
    site: "https://www.amn.pt/Paginas/Homepage.aspx",
    phone: "210 911 149",
    name: "AMN",
    image:
      "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/Logotipo_AMN_af84fc9bac.png",
    siteShort: "amn.pt",
  },
  apav: {
    site: "https://apav.pt",
    phone: "116006",
    name: "APAV",
    image:
      "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/apav_8499016049.png",
    siteShort: "apav.pt",
  },
  apaven: {
    site: "https://apav.pt",
    phone: "116006",
    name: "APAV",
    image:
      "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/apav_en_1_3a54d5250e.png",
    siteShort: "apav.pt",
  },
};
const SecurityForces = () => {
  const [name, setName] = useState("");
  const [intro, setIntro] = useState("");
  const [image, setImage] = useState("");
  const [site, setSite] = useState("");
  const [siteShort, setSiteShort] = useState("");
  const [phone, setPhone] = useState("");
  const [styles, setStyles] = useState("");
  const [locale, setLocale] = useState("pt");
  const [currentForce, setCurrentForce] = useState("");

  useEffect(() => {
    fetch("/style-security-forces.css.txt")
      .then((r) => r.text())
      .then((cssString) => {
        const styleEl = document.createElement("style");
        styleEl.innerHTML = cssString;
        setStyles("<style>" + cssString + "</style>");
        document.head.appendChild(styleEl);
        return () => {
          document.head.removeChild(styleEl);
        };
      });
  }, []);

  useEffect(() => {
    if (currentForce !== "") {
      const f = forces[currentForce];
      setImage(f.image);
      setPhone(f.phone);
      setName(f.intro);
      setName(f.name);
      setSite(f.site);
      setSiteShort(f.siteShort);
    }
  }, [currentForce]);

  const getHTML = (retElement = false) => {
    const el = (
      <Template
        intro={intro}
        phone={phone}
        site={site}
        siteShort={siteShort}
        name={name}
        logo={image}
        locale={locale}
      />
    );
    if (retElement) {
      return el;
    }
    const htmlString = renderToStaticMarkup(el);
    return styles + "\r\n" + htmlString;
  };
  return (
    <Container>
      <Form>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Intro</Form.Label>
              <Form.Control
                as="textarea"
                rows={15}
                value={intro}
                onChange={(e) => setIntro(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Idioma</Form.Label>
              <Form.Select
                value={locale}
                onChange={(e) => setLocale(e.target.value)}
                aria-label="Default select example"
              >
                <option value="pt">pt</option>
                <option value="en">en</option>
                <option value="es">es</option>
                <option value="fr">fr</option>
                <option value="it">it</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Força</Form.Label>
              <Form.Select
                value={currentForce}
                onChange={(e) => setCurrentForce(e.target.value)}
                aria-label="Default select example"
              >
                <option value=""> - </option>
                <option value="psp">PSP</option>
                <option value="gnr">GNR</option>
                <option value="pj">PJ</option>
                <option value="sns">SNS</option>
                <option value="inem">INEM</option>
                <option value="sef">sef</option>
                <option value="anepc">ANEPC</option>
                <option value="amn">AMN</option>
                <option value="apav">APAV PT</option>
                <option value="apaven">APAV EN</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Nome"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Imagem</Form.Label>
              <Form.Control
                value={image}
                onChange={(e) => setImage(e.target.value)}
                type="text"
                placeholder="Imagem"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Site</Form.Label>
              <Form.Control
                value={site}
                onChange={(e) => setSite(e.target.value)}
                type="text"
                placeholder="Site"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Site Short</Form.Label>
              <Form.Control
                value={siteShort}
                onChange={(e) => setSiteShort(e.target.value)}
                type="text"
                placeholder="Site (short version)"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Telefone</Form.Label>
              <Form.Control
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                placeholder="Telefone"
              />
            </Form.Group>
          </Col>
        </Row>
        <Copy text={getHTML()} />
      </Form>
      {getHTML(true)}
    </Container>
  );
};

export default SecurityForces;
