const schema = {
  pt: {
    category: "Session Catergory",
    name: "Name_PT",
    start_time: "Start Time",
    end_time: "End Time",
    location: "Place CategorySessão",
    room: "Room",
    subtitle: "subtitle",
    link: "link",
    readmore: "ver mais",
  },
  en: {
    category: "Session Catergory",
    name: "Name_EN",
    start_time: "Start Time",
    end_time: "End Time",
    location: "Place CategorySessão",
    room: "Room",
    subtitle: "subtitle",
    link: "link",
    readmore: "read more",
  },
  es: {
    category: "Session Catergory",
    name: "Name_ES",
    start_time: "Start Time",
    end_time: "End Time",
    location: "Place CategorySessão",
    room: "Room",
    subtitle: "subtitle",
    link: "link",
    readmore: "read more",
  },
  fr: {
    category: "Session Catergory",
    name: "Name_FR",
    start_time: "Start Time",
    end_time: "End Time",
    location: "Place CategorySessão",
    room: "Room",
    subtitle: "subtitle",
    link: "link",
    readmore: "read more",
  },
  it: {
    category: "Session Catergory",
    name: "Name_IT",
    start_time: "Start Time",
    end_time: "End Time",
    location: "Place CategorySessão",
    room: "Room",
    subtitle: "subtitle",
    link: "link",
    readmore: "read more",
  }
}

export default schema;