import React, { useEffect, useState } from "react";
import Copy from "../../Copy";
import { Col, Container, Row, Form } from "react-bootstrap";
import { renderToStaticMarkup } from "react-dom/server";
import CustomTag from "./custom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";

const Typography = () => {
  const [selectedElement, setSelectedElement] = useState("iconlink");
  const [text, setText] = useState("");
  const [icon, setIcon] = useState(
    "https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/pdf_file_2614_cc8e8b8e1f.png?updated_at=2023-03-28T17:42:19.216Z"
  );
  const [alt, setAlt] = useState("");
  const [url, setUrl] = useState("");
  const [svgIcon, setSvgIcon] = useState("");
  const [color, setColor] = useState("");
  const [marginTop, setMarginTop] = useState(0);
  const [marginBottom, setMarginBottom] = useState(0);
  const [style, setStyle] = useState({});
  const [bold, setBold] = useState(false);
  const [italic, setItalic] = useState(false);
  const [underline, setUnderline] = useState(false);
  const [newWindow, setNewWindow] = useState(true);
  const [useSvgIcon, setUseSvgIcon] = useState(true);
  const [iconOnRight, setIconOnRight] = useState(false);
  const [svgColor, setSvgColor] = useState("")
  const [svgWidth, setSvgWidth] = useState("auto")
  const [svgHeight, setSvgHeight] = useState("1rem")
  const [svgIconComponent, setSvgIconComponent] = useState("");

  useEffect(() => {
    const currentStyle = {
      color: color,
      marginTop: `${marginTop}px`,
      marginBottom: `${marginBottom}px`,
    };
    if (bold) {
      currentStyle.fontWeight = "bold";
    }
    if (italic) {
      currentStyle.fontStyle = "italic";
    }
    if (underline) {
      currentStyle.textDecoration = "underline";
    }
    setStyle(currentStyle);
  }, [color, marginTop, marginBottom, bold, italic, underline]);


  const getHTML = () => {
    const htmlString = renderToStaticMarkup(
      <CustomTag
        text={text}
        url={url}
        newWindow={newWindow}
        tag={selectedElement}
        style={style}
        icon={icon}
        alt={alt}
        useSvgIcon={useSvgIcon}
        svgIconComponent={svgIconComponent}
        svgIcon={svgIcon}
        iconOnRight={iconOnRight}
        svgColor={svgColor}
        svgWidth={svgWidth}
        svgHeight={svgHeight}
      />
    );
    return htmlString;
  };

  return (
    <Container>
      <Form>
        <Form.Group className="mb-3">
          <Form.Label>Texto</Form.Label>
          <Form.Control
            value={text}
            onChange={(e) => setText(e.target.value)}
            type="text"
            placeholder="texto para preparar"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>URL</Form.Label>
          <Form.Control
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            type="text"
            placeholder="http://lisboa2023.org"
          />
          <Form.Text className="text-muted">
            Se preenchido vai introduzir um link dentro do elemento escolhido
          </Form.Text>
        </Form.Group>
        {selectedElement === "iconlink" && !useSvgIcon && (
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Icon</Form.Label>
                <Form.Control
                  value={icon}
                  onChange={(e) => setIcon(e.target.value)}
                  type="text"
                  placeholder="url da imagem do icon"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Alt Text</Form.Label>
                <Form.Control
                  value={alt}
                  onChange={(e) => setAlt(e.target.value)}
                  type="text"
                  placeholder="alt text da imagem do icon"
                />
              </Form.Group>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Tipo de elemento</Form.Label>
              <Form.Select
                value={selectedElement}
                onChange={(e) => setSelectedElement(e.target.value)}
                aria-label="Default select example"
              >
                <option value="h1">h1</option>
                <option value="h2">h2</option>
                <option value="h3">h3</option>
                <option value="h4">h4</option>
                <option value="h5">h5</option>
                <option value="h6">h6</option>
                <option value="p">Paragrafo</option>
                <option value="div">DIV</option>
                <option value="a">Link</option>
                <option value="iconlink">Link com Icon</option>
                <option value="jmj-button">Link Botão</option>
                <option value="icon-text">Icon com texto</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Cor</Form.Label>
              <Form.Select
                value={color}
                onChange={(e) => setColor(e.target.value)}
                aria-label="Default select example"
              >
                <option value="">Nenhuma</option>
                <option value="#00873e">Verde</option>
                <option value="#FAC112">Amarelo</option>
                <option value="#d63f31">Vermelho</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Margem Superior (px)</Form.Label>
              <Form.Control
                value={marginTop}
                onChange={(e) => setMarginTop(e.target.value)}
                type="number"
                step="1"
                placeholder=""
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Margem Inferior (px)</Form.Label>
              <Form.Control
                value={marginBottom}
                onChange={(e) => setMarginBottom(e.target.value)}
                type="number"
                step="1"
                placeholder=""
              />
            </Form.Group>
          </Col>
        </Row>
        {useSvgIcon &&
          ["iconlink", "jmj-button", "icon-text"].includes(selectedElement) && (
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Icon</Form.Label>
                  <Form.Select
                    value={svgIconComponent}
                    onChange={(e) => setSvgIconComponent(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option value="">Nenhum</option>
                    <option value="phone">Phone</option>
                    <option value="site">Site</option>
                    <option value="file">Ficheiro</option>
                    <option value="pdf">PDF</option>
                    <option value="audio">Ficheiro Audio</option>
                    <option value="location">Localização</option>
                    <option value="arrowright">Seta para direita</option>
                    <option value="wc">Acessibilidade - WC</option>
                    <option value="audio_desc">
                      Acessibilidade - Audio Descricao
                    </option>
                    <option value="space_accessible">
                      Acessibilidade - Espaço Acessivel
                    </option>
                    <option value="sign_language">Acessibilidade - LGP</option>
                    <option value="other">Outro</option>
                  </Form.Select>
                </Form.Group>
                {svgIconComponent === "other" && (
                  <Form.Group className="mb-3">
                    <Form.Label>SVG</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      value={svgIcon}
                      onChange={(e) => setSvgIcon(e.target.value)}
                    />
                  </Form.Group>
                )}
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Cor Icon</Form.Label>
                  <Form.Select
                    value={svgColor}
                    onChange={(e) => setSvgColor(e.target.value)}
                    aria-label="Default select example"
                  >
                    <option value="">Nenhuma</option>
                    <option value="#00873e">Verde</option>
                    <option value="#FAC112">Amarelo</option>
                    <option value="#d63f31">Vermelho</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Largura Icon</Form.Label>
                  <Form.Control
                    value={svgWidth}
                    onChange={(e) => setSvgWidth(e.target.value)}
                    type="text"
                    placeholder="texto para preparar"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Altura Icon</Form.Label>
                  <Form.Control
                    value={svgHeight}
                    onChange={(e) => setSvgHeight(e.target.value)}
                    type="text"
                    placeholder="texto para preparar"
                  />
                </Form.Group>
              </Col>
            </Row>
          )}
        <Row>
          <Col>
            <Form.Check // prettier-ignore
              inline
              type="switch"
              id="bold-switch"
              label="Bold"
              checked={bold}
              onChange={(e) => setBold(() => !bold)}
            />
            <Form.Check // prettier-ignore
              inline
              checked={italic}
              onChange={(e) => setItalic(() => !italic)}
              type="switch"
              id="italic-switch"
              label="Itálico"
            />
            <Form.Check // prettier-ignore
              inline
              checked={underline}
              onChange={(e) => setUnderline(() => !underline)}
              type="switch"
              id="underline-switch"
              label="Sublinhado"
            />
            <Form.Check // prettier-ignore
              inline
              checked={newWindow}
              onChange={(e) => setNewWindow(() => !newWindow)}
              type="switch"
              id="new-switch"
              label="Abrir numa nova janela"
            />
            <Form.Check // prettier-ignore
              inline
              checked={useSvgIcon}
              onChange={(e) => setUseSvgIcon(() => !useSvgIcon)}
              type="switch"
              id="svg-switch"
              label="Icon SVG"
            />
            <Form.Check // prettier-ignore
              inline
              checked={iconOnRight}
              onChange={(e) => setIconOnRight(() => !iconOnRight)}
              type="switch"
              id="svg-switch"
              label="Icon à direita"
            />
          </Col>
        </Row>
      </Form>
      <div className="mt-3 mb-3">
        <Copy text={getHTML()} />
      </div>
      <Row>
        <Col>
          <div>Preview:</div>
          <div className="mt-5">
            <p style={{ marginBottom: 0 }}>Lorem ipsum</p>
            <CustomTag
              text={text}
              url={url}
              newWindow={newWindow}
              tag={selectedElement}
              style={style}
              icon={icon}
              alt={alt}
              useSvgIcon={useSvgIcon}
              svgIconComponent={svgIconComponent}
              svgIcon={svgIcon}
              svgColor={svgColor}
              svgWidth={svgWidth}
              iconOnRight={iconOnRight}
              svgHeight={svgHeight}
            />
            <p>Lorem ipsum</p>
          </div>
        </Col>
        <Col>
          <p>
            Tipo: <strong>{selectedElement}</strong><br />
            Texto: <strong>{text}</strong><br />
            Link: <strong>{url}</strong><br />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Typography;
