import React from "react";


const DownloadLink = ({ file, name }) => (
  <div className="jmj-link-holder">
    <div className="jmj-link-icon">
      <a href={file} target="_blank" rel="noreferrer">
        <img
          src="https://EPJMJ2023QABLOB-gmehapawbmdqb2cb.z01.azurefd.net/blbqajmj2023/assets/pdf_file_2614_cc8e8b8e1f.png?updated_at=2023-03-28T17:42:19.216Z"
          alt="PDF"
        />
      </a>
    </div>
    <div className="jmj-link-text link-underline">
      <span>
        <a href={file} target="_blank" rel="noreferrer">{name}</a>
      </span>
    </div>
  </div>
);


const Links = ({ items }) => {
  console.log(items);
  return (
    <div>
      {items.map((item) => (<DownloadLink file={item.file} name={item.name} />))}
    </div>
  )
}

export default Links;