export const SiteIcon = ({ alt }) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 511.999 511.999"
      width="20"
      alt={alt}
    >
      <g>
        <g>
          <path
            d="M437.019,74.981C388.667,26.628,324.38,0,256,0C187.62,0,123.332,26.628,74.981,74.98C26.628,123.332,0,187.62,0,256
			s26.628,132.667,74.981,181.019C123.332,485.371,187.62,511.999,256,511.999c68.381,0,132.667-26.628,181.02-74.981
			c48.351-48.351,74.98-112.639,74.98-181.019S485.371,123.332,437.019,74.981z M96.216,96.216
			c22.511-22.511,48.938-39.681,77.742-50.888c-7.672,9.578-14.851,20.587-21.43,32.969c-7.641,14.38-14.234,30.173-19.725,47.042
			c-19.022-3.157-36.647-7.039-52.393-11.595C85.345,107.678,90.61,101.822,96.216,96.216z M62.229,139.585
			c18.417,5.897,39.479,10.87,62.461,14.809c-6.4,27.166-10.167,56.399-11.066,86.591H30.536
			C32.896,204.752,43.778,170.172,62.229,139.585z M60.594,369.638c-17.455-29.899-27.769-63.481-30.059-98.623h83.146
			c0.982,29.329,4.674,57.731,10.858,84.186C101.085,359.003,79.494,363.85,60.594,369.638z M96.216,415.784
			c-6.38-6.381-12.322-13.081-17.831-20.055c16.323-4.526,34.571-8.359,54.214-11.433c5.53,17.103,12.194,33.105,19.928,47.662
			c7.17,13.493,15.053,25.349,23.51,35.505C146.427,456.28,119.268,438.834,96.216,415.784z M240.984,478.115
			c-22.808-6.389-44.384-27.217-61.936-60.249c-6.139-11.552-11.531-24.155-16.15-37.587c24.73-2.722,51.045-4.331,78.086-4.709
			V478.115z M240.984,345.537c-29.988,0.409-59.217,2.292-86.59,5.507c-6.038-24.961-9.671-51.978-10.668-80.028h97.259V345.537z
			 M240.984,240.984h-97.315c0.911-28.834,4.602-56.605,10.828-82.201c27.198,3.4,56.366,5.468,86.487,6.06V240.984z
			 M240.984,134.808c-27.146-0.547-53.403-2.317-77.958-5.205c4.591-13.292,9.941-25.768,16.022-37.215
			c17.551-33.032,39.128-53.86,61.936-60.249V134.808z M450.717,141.18c17.874,30.193,28.427,64.199,30.749,99.804h-83.088
			c-0.889-29.844-4.584-58.749-10.85-85.647C410.661,151.601,431.984,146.848,450.717,141.18z M415.783,96.216
			c6.029,6.029,11.661,12.349,16.914,18.91c-16.073,4.389-33.972,8.114-53.204,11.112c-5.548-17.208-12.243-33.305-20.02-47.941
			c-6.579-12.382-13.758-23.391-21.43-32.969C366.845,56.535,393.273,73.705,415.783,96.216z M271.016,271.016h97.259
			c-1.004,28.268-4.686,55.49-10.81,80.612c-27.194-3.381-56.349-5.43-86.449-6.006V271.016z M271.016,240.984v-76.041
			c30.005-0.394,59.257-2.261,86.656-5.464c6.125,25.403,9.756,52.932,10.659,81.505H271.016z M271.014,32.139h0.001
			c22.808,6.389,44.384,27.217,61.936,60.249c6.178,11.627,11.601,24.318,16.24,37.848c-24.763,2.712-51.108,4.309-78.177,4.674
			V32.139z M271.016,478.115V375.657c27.12,0.532,53.357,2.286,77.903,5.156c-4.579,13.232-9.911,25.654-15.967,37.053
			C315.4,450.898,293.824,471.726,271.016,478.115z M415.783,415.784c-23.051,23.051-50.21,40.496-79.821,51.678
			c8.457-10.156,16.34-22.011,23.51-35.504c7.62-14.341,14.198-30.088,19.68-46.906c19.465,3.213,37.473,7.186,53.515,11.859
			C427.424,403.457,421.801,409.765,415.783,415.784z M450.606,371.009c-18.635-5.991-40-11.032-63.326-15.01
			c6.296-26.68,10.048-55.36,11.041-84.983h83.146C479.139,306.694,468.549,340.769,450.606,371.009z"
          />
        </g>
      </g>
    </svg>
  );
}

export const FileIcon = ({alt}) => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m433.798 106.268-96.423-91.222c-10.256-9.703-23.68-15.046-37.798-15.046h-183.577c-30.327 0-55 24.673-55 55v402c0 30.327 24.673 55 55 55h280c30.327 0 55-24.673 55-55v-310.778c0-15.049-6.27-29.612-17.202-39.954zm-29.137 13.732h-74.661c-2.757 0-5-2.243-5-5v-70.364zm-8.661 362h-280c-13.785 0-25-11.215-25-25v-402c0-13.785 11.215-25 25-25h179v85c0 19.299 15.701 35 35 35h91v307c0 13.785-11.215 25-25 25z" />
      <path d="m363 200h-220c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
      <path d="m363 280h-220c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
      <path d="m215.72 360h-72.72c-8.284 0-15 6.716-15 15s6.716 15 15 15h72.72c8.284 0 15-6.716 15-15s-6.716-15-15-15z" />
    </svg>
  );
}

export const PDFIcon = ({alt}) => {
  return (
    <svg
      id="Capa_1"
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m481.059 77.662-70.772-70.77c-4.445-4.444-10.354-6.892-16.64-6.892h-297.97c-12.975 0-23.532 10.557-23.532 23.532v232.984h-16.548c-17.396 0-31.548 14.153-31.548 31.548v144.29c0 17.396 14.152 31.549 31.548 31.549h248.5c12.976 0 23.532-10.557 23.532-23.532v-112.225c0-12.976-10.557-23.533-23.532-23.533h-248.5c-9.125 0-16.548-7.423-16.548-16.548s7.423-16.548 16.548-16.548h16.548v8.535c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-256.52c0-4.705 3.828-8.532 8.532-8.532h289.097v72.661c0 8.556 6.96 15.517 15.516 15.517h72.661v385.29c0 4.705-3.828 8.532-8.532 8.532h-368.742c-4.705 0-8.532-3.827-8.532-8.532 0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5c0 12.976 10.557 23.532 23.532 23.532h368.742c12.976 0 23.532-10.557 23.532-23.532v-394.166c.001-6.285-2.447-12.195-6.892-16.64zm-425.462 241.951h248.5c4.705 0 8.532 3.828 8.532 8.533v112.226c0 4.705-3.828 8.532-8.532 8.532h-248.5c-9.125 0-16.548-7.424-16.548-16.549v-117.44c4.813 2.979 10.483 4.698 16.548 4.698zm344.177-231.952v-70.068l70.587 70.585h-70.071c-.284 0-.516-.232-.516-.517z" />
        <path d="m236.571 423.588c4.142 0 7.5-3.358 7.5-7.5v-24.933h16.251c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-16.251v-16.227h18.301c4.142 0 7.5-3.358 7.5-7.5 0-4.143-3.358-7.5-7.5-7.5h-25.801c-4.142 0-7.5 3.357-7.5 7.5v63.659c0 4.142 3.358 7.501 7.5 7.501z" />
        <path d="m157.74 420.697c1.405 1.395 3.305 2.177 5.284 2.177h.028c.533-.002 13.098-.05 18.117-.138 18.533-.324 31.985-16.506 31.985-38.478 0-23.097-13.113-38.616-32.631-38.616h-17.625c-1.992 0-3.901.792-5.308 2.202s-2.196 3.321-2.192 5.312v.049.001 61.157c0 .515.052 1.018.151 1.504.12 1.819.89 3.539 2.191 4.83zm22.783-60.055c13 0 17.631 12.199 17.631 23.616 0 16.012-8.662 23.331-17.247 23.48-2.386.042-6.643.075-10.409.098-.024-7.358-.074-39.54-.087-47.194z" />
        <path d="m95.445 423.822c4.142 0 7.5-3.357 7.5-7.5v-20.396c3.724-.02 7.958-.039 10.356-.039 14.31 0 25.952-11.482 25.952-25.597s-11.642-25.598-25.952-25.598h-17.856c-1.993 0-3.904.794-5.312 2.205-1.402 1.406-2.187 3.311-2.187 5.295h-.001v64.129c0 4.144 3.358 7.501 7.5 7.501zm17.856-64.129c5.937 0 10.952 4.854 10.952 10.598s-5.015 10.597-10.952 10.597c-2.382 0-6.563.019-10.273.038-.02-3.831-.05-17.224-.061-21.232h10.334z" />
        <path d="m235.948 217.462c-18.433 5.818-37.352 13.871-47.444 24.288-8.327 8.595-9.836 18.567-4.141 27.36 3.865 5.967 9.178 9.086 15.152 9.086 2.293 0 4.684-.46 7.127-1.394 8.813-3.37 18.331-12.957 29.098-29.31 3.476-5.279 6.995-11.146 10.498-17.493 1.275-.375 2.575-.748 3.905-1.12 21.166-5.922 45.112-10.22 66.128-12.016 12.811 12.388 26.053 22.04 37.342 24.673 12.419 2.897 22.265-2.782 26.332-15.192 2.463-7.517-.373-14.605-7.588-18.96-7.418-4.479-20.309-6.642-38.306-6.436-3.833.044-7.879.2-12.089.461-8.085-8.338-16.497-18.303-24.598-29.308-6.276-8.525-11.886-17.056-16.63-25.165.516-1.777 1.006-3.53 1.471-5.252 5.127-19.013 6.956-33.57 5.435-43.268-2.197-14.005-11.236-15.994-15.02-16.198-12.885-.697-21.564 5.804-23.215 17.389-1.312 9.203 1.783 21.865 9.198 37.633 1.763 3.748 3.74 7.611 5.911 11.556-.809 2.596-1.686 5.297-2.636 8.108-6.914 20.454-16.278 42.249-25.93 60.558zm-36.017 45.694c-.535.067-1.437.18-2.979-2.201-1.255-1.938-1.323-3.4-.252-5.396 2.504-4.668 11.053-11.218 27.596-17.984-11.546 17.908-20.175 25.058-24.365 25.581zm165.884-41.993c-.011.093-.041.259-.124.511-1.873 5.716-4.39 6.255-8.671 5.256-5.226-1.219-11.864-5.08-19.268-10.989 20.048.16 27.042 3.772 28.063 5.222zm-101.561-119.438c.306-2.144.649-4.555 6.355-4.555.359 0 .74.01 1.142.029 1.708 2.081 2.587 11.34-1.365 29.664-4.655-10.675-6.929-19.543-6.132-25.138zm10.444 64.004c3.292 5.059 6.833 10.168 10.586 15.266 5.384 7.314 11.466 14.907 17.899 22.155-7.442.898-15.118 2.057-22.797 3.451-5.196.943-14.255 2.722-24.859 5.367 2.352-4.877 4.671-9.932 6.94-15.13 4.51-10.329 8.658-20.898 12.231-31.109z" />
      </g>
    </svg>
  );
}

export const AudioIcon = ({alt}) => {
  return (
    <svg
      id="Layer_1"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m497 271.667h-30.608v-256.667c0-8.284-6.716-15-15-15h-293.631c-.014 0-.028.002-.042.002s-.028-.002-.042-.002c-4.589 0-8.691 2.066-11.443 5.313l-96.23 96.185c-2.175 2.401-4.431 5.69-4.396 10.609v159.56h-30.608c-8.284 0-15 6.716-15 15v210.333c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-210.333c0-8.285-6.716-15-15-15zm-354.323-220.382v45.822h-45.843zm-67.069 75.822h82.068c8.284 0 15-6.716 15-15v-82.107h263.715v241.667h-360.783zm406.392 354.893h-452v-180.333h452z" />
        <path d="m192.472 328.939c-5.962-2.134-12.626-.294-16.647 4.602l-28.807 35.076-30.88-35.414c-4.11-4.712-10.712-6.383-16.569-4.188-5.856 2.194-9.736 7.792-9.736 14.046v97.545c0 8.284 6.716 15 15 15s15-6.716 15-15v-57.519l16.223 18.605c2.899 3.325 7.098 5.191 11.527 5.14 4.411-.065 8.57-2.069 11.37-5.479l13.464-16.394v55.646c0 8.284 6.716 15 15 15s15-6.716 15-15v-97.545c-.001-6.334-3.981-11.986-9.945-14.121z" />
        <path d="m271.237 328.061h-24.425c-8.284 0-15 6.716-15 15v97.545c0 8.284 6.716 15 15 15s15-6.716 15-15v-28.631h9.425c22.688 0 41.146-18.822 41.146-41.957s-18.458-41.957-41.146-41.957zm0 53.914h-9.425v-23.914h9.425c6.146 0 11.146 5.364 11.146 11.957s-5 11.957-11.146 11.957z" />
        <path d="m393.723 375.569 17.623-23.512c3.407-4.545 3.954-10.626 1.413-15.707-2.542-5.081-7.735-8.29-13.416-8.29h-48.323c-8.284 0-15 6.716-15 15s6.716 15 15 15h18.333l-16.149 21.545c-3.407 4.545-3.954 10.626-1.413 15.707 2.542 5.081 7.735 8.29 13.416 8.29h15.959c6.066 0 11.001 4.935 11.001 11.001s-4.935 11.001-11.001 11.001h-30.146c-8.284 0-15 6.716-15 15s6.716 15 15 15h30.146c22.608 0 41.001-18.393 41.001-41.001.001-18.228-11.959-33.718-28.444-39.034z" />
        <path d="m320.935 82.091-31.032-20.645c-4.603-3.062-10.519-3.345-15.393-.733-4.874 2.611-7.916 7.692-7.916 13.222v93.275c-3.582-1.019-7.356-1.577-11.26-1.577-22.75 0-41.26 18.531-41.26 41.308s18.509 41.308 41.26 41.308 41.26-18.531 41.26-41.308c0-.047-.003-.094-.004-.141 0-.024.004-.047.004-.071v-104.8l7.724 5.138c9.505 6.323 14.14 17.644 11.808 28.841-1.689 8.11 3.517 16.054 11.626 17.744 8.109 1.687 16.054-3.517 17.744-11.626 4.844-23.26-4.796-46.786-24.561-59.935zm-65.601 136.159c-6.208 0-11.26-5.073-11.26-11.308s5.051-11.308 11.26-11.308 11.26 5.073 11.26 11.308-5.051 11.308-11.26 11.308z" />
      </g>
    </svg>
  );
}

export const LocationIcon = ({alt}) => {
  return (
    <svg
      enableBackground="new 0 0 24 24"
      height="512"
      viewBox="0 0 24 24"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m12 0c-4.962 0-9 4.066-9 9.065 0 7.103 8.154 14.437 8.501 14.745.143.127.321.19.499.19s.356-.063.499-.189c.347-.309 8.501-7.643 8.501-14.746 0-4.999-4.038-9.065-9-9.065zm0 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z" />
    </svg>
  );
}

export const ArrowRightIcon = ({ alt }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      aria-hidden="true"
      className="w-5"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
      ></path>
    </svg>
  );
};

export const PhoneIcon = ({alt}) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 513.64 513.64"
      width="20"
      alt={alt}
    >
      <g>
        <g>
          <path
            d="M499.66,376.96l-71.68-71.68c-25.6-25.6-69.12-15.359-79.36,17.92c-7.68,23.041-33.28,35.841-56.32,30.72
			c-51.2-12.8-120.32-79.36-133.12-133.12c-7.68-23.041,7.68-48.641,30.72-56.32c33.28-10.24,43.52-53.76,17.92-79.36l-71.68-71.68
			c-20.48-17.92-51.2-17.92-69.12,0l-48.64,48.64c-48.64,51.2,5.12,186.88,125.44,307.2c120.32,120.32,256,176.641,307.2,125.44
			l48.64-48.64C517.581,425.6,517.581,394.88,499.66,376.96z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SiteIcon