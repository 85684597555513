import React, { useEffect, useState } from "react";
import Copy from "../../Copy";
import { Col, Container, Row, Form } from "react-bootstrap";
import { renderToStaticMarkup } from "react-dom/server";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import filesDefinitions from "./files";
import Links from "./links";
import CSVReader from "../../CsvReader";

const css = `<style>
  .jmj-link-holder {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
  }

  .jmj-link-holder .jmj-link-icon {
    display: flex;
  }

  .jmj-link-holder .jmj-link-icon img {
    width: 50px;
  }

  .jmj-link-holder .jmj-link-text {
    display: flex;
    align-items: center;
  }
</style>`;

const Partituras = () => {
  const [preparedData, setPreparedData] = useState({});
  const [preparedDataHTML, setPreparedDataHTML] = useState({});

  const getHTML = (data) => {
    const htmlString = renderToStaticMarkup(<Links items={data} />);
    return htmlString;
  };

  const prepareData = (data) => {
    const prepared = {}
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const evt = item.Event;
      if (evt) {
        if (typeof prepared[evt] === "undefined") {
          prepared[evt] = [];
        }
        prepared[evt].push({
          name: item.Name,
          file: item.File,
        })
      }
    }

    return prepared;
  };

  useEffect(() => {
    const keys = Object.keys(preparedData);
    const htmlData = {};
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      htmlData[key] = getHTML(preparedData[key]);
    }
    setPreparedDataHTML(htmlData);
  }, [preparedData]);

  return (
    <Container className="mt-5 mb-5">
      <Row className="mb-5">
        <Col md="6">
          <CSVReader
            onUploadAccepted={(results) => {
              setPreparedData({});
              const d = prepareData(results.data);
              setPreparedData(d);
            }}
          ></CSVReader>
        </Col>
      </Row>
      <div>
        CSS: <Copy text={css} />
      </div>

        {Object.keys(preparedDataHTML).map((key) => {
          return (
            <>
            <div className="mt-3">
              <h3>{key}</h3>
            </div>
            <div className="mb-3">
                HTML: <Copy text={preparedDataHTML[key]} />
            </div>
            </>
          )
         })}
    </Container>
  );
};

export default Partituras;
