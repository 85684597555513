import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import { SiteIcon, PhoneIcon } from "../icons";

const lang = {
  pt: {
    phone: "Telefone",
    site: "Website",
  },
  en: {
    phone: "Telephone",
    site: "Website",
  },
  fr: {
    phone: "Téléphone",
    site: "Site web",
  },
  es: {
    phone: "Teléfono",
    site: "Web",
  },
  it: {
    phone: "Telefono",
    site: "Web",
  },
};
const Template = ({ name, siteShort, site, phone, intro, logo, locale }) => {

  const [t, setT] = useState({});

  useEffect(() => {
    const alts = lang[locale];
    setT(alts);
  }, [locale]);
  return (
    <div className="security-forces-intro">
      <div
        className="security-forces-desc"
        dangerouslySetInnerHTML={{ __html: intro }}
      ></div>
      <div className="security-forces-details">
        <div className="detail-value">
          <a href={site} target="_blank" rel="noreferrer">
            <img alt={name} src={logo} />
          </a>
        </div>
        <div className="detail-value">
            {site && (
          <div className="contact">
              <>
                <div className="contact-type">
                  <SiteIcon alt={t.site} />
                </div>
                <div className="contact-value">
                  <a href={site} target="_blank" rel="noreferrer">
                    {siteShort}
                  </a>
                </div>
              </>
          </div>
          )}
          {phone && (
            <div className="contact">
              <div className="contact-type">
                <PhoneIcon alt={t.phone} />
              </div>
              <div className="contact-value">
                <a href={`tel:00351${phone.replace(/ /g, "")}`}>(+351) {phone}</a>
              </div>
            </div>
          )}
          </div>
      </div>
    </div>
  );
};

export default Template;
