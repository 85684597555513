import { useState } from "react";
import DayTemplate from "./template";
import { Button } from "react-bootstrap";

const Preview = ({data, locale}) => {
  const [show, setShow] = useState(false);
  const togglePreview = () => {
    setShow(!show);
  }
  return (
    <div className="preview">
      <Button onClick={togglePreview}>Toggle preview</Button>
      {show && <DayTemplate item={data} locale={locale} />}
    </div>
  );
};

export default Preview;
