import React from "react";
import {
  PhoneIcon,
  SiteIcon,
  FileIcon,
  LocationIcon,
  AudioIcon,
  PDFIcon,
  ArrowRightIcon,
} from "../icons";

import { ReactComponent as WC2 } from "../../assets/wc2.svg";
import { ReactComponent as AudioDesc } from "../../assets/audio-desc.svg";
import { ReactComponent as SignLanguage } from "../../assets/sign-language.svg";
import { ReactComponent as SpaceAccessible } from "../../assets/space-accessible.svg";

function generateRandomHex() {
  // Generate a random number between 0 and 16777215 (corresponding to FFFFFF in hexadecimal)
  const randomNumber = Math.floor(Math.random() * 16777216);

  // Convert the number to a hexadecimal string and pad it with leading zeros to ensure it has six digits
  const hexValue = randomNumber.toString(16).padStart(6, "0");

  return hexValue; // Return the final hex value with a '#' prefix
}


const css = `.jmj-link-holder {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
  }

  .jmj-link-holder .jmj-link-icon {
    display: flex;
  }

  .jmj-link-holder .jmj-link-icon img {
    width: 50px;
  }

  .jmj-link-holder .jmj-link-text {
    display: flex;
    align-items: center;
  }
  `;

const svgCSS = `
.svg-BTNUNIQUE-icon-link-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.svg-BTNUNIQUE-icon-link-holder svg {
  display: inline-block;
  height: SVGHEIGHT;
  width: SVGWIDTH;
}

.svg-BTNUNIQUE-icon-link-holder svg path{
  fill: SVGCOLOR;
}

.svg-BTNUNIQUE-icon {
  margin-right: 10px;
}
.svg-BTNUNIQUE-link {
  margin-right: 10px;
}
`;

const btnCSVCSS = `
.btn-BTNUNIQUE-icon {
  display: flex;
}

.btn-BTNUNIQUE-icon svg {
  display: inline-block;
  height: SVGHEIGHT;
  width: SVGWIDTH;
}

.btn-BTNUNIQUE-icon svg path{
  fill: SVGCOLOR;
}

`;

const btnCSS = `
a.btn-BTNUNIQUE {
  color: rgb(255 255 255);
  background-color: BTNCOLOR;
  border-color: BTNCOLOR;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,-webkit-backdrop-filter;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter;
  transition-property: color,background-color,border-color,text-decoration-color,fill,stroke,opacity,box-shadow,transform,filter,backdrop-filter,-webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: .25rem;
  align-items: center;
  gap: .5rem;
  border-width: 2px;
  height: 3rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
}

a.btn-BTNUNIQUE:active, a.btn-BTNUNIQUE:focus, a.btn-BTNUNIQUE:hover, button.btn-BTNUNIQUE:active, button.btn-BTNUNIQUE:focus, button.btn-BTNUNIQUE:hover {
  color: rgb(255 255 255);
  border-width: 2px;
  border-color: rgb(0 102 49);
}

a.btn-BTNUNIQUE:active, a.btn-BTNUNIQUE:hover, button.btn-BTNUNIQUE:active, button.btn-BTNUNIQUE:hover {
  background: BTNHOVERCOLOR;
}
`;


const svgTextCSS = `
.svg-BTNUNIQUE-icon-text-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.svg-BTNUNIQUE-icon-text-holder svg {
  display: inline-block;
  height: SVGHEIGHT;
  width: SVGWIDTH;
}

.svg-BTNUNIQUE-icon-text-holder svg path{
  fill: SVGCOLOR;
}

.svg-BTNUNIQUE-icon {
  margin-right: 10px;
}
.svg-BTNUNIQUE-text {
  margin-right: 10px;
}
`;

const DownloadLink = ({ url, text, icon, alt, style }) => (
  <>
    <style>{css}</style>
    <div className="jmj-link-holder">
      <div className="jmj-link-icon">
        <a href={url} target="_blank" rel="noreferrer">
          <img src={icon} alt={alt} />
        </a>
      </div>
      <div className="jmj-link-text link-underline">
        <span>
          <a href={url} style={style} target="_blank" rel="noreferrer">
            {text}
          </a>
        </span>
      </div>
    </div>
  </>
);

const CustomSvg = ({svgIcon}) => {
  return (
    <div
      className="svg-link-icon"
      dangerouslySetInnerHTML={{ __html: svgIcon }}
    ></div>
  );
}


const IconComponent = (svgIconComponent) => {
  const components = {
    phone: PhoneIcon,
    site: SiteIcon,
    file: FileIcon,
    pdf: PDFIcon,
    location: LocationIcon,
    audio: AudioIcon,
    arrowright: ArrowRightIcon,
    other: CustomSvg,
    wc: WC2,
    audio_desc: AudioDesc,
    space_accessible: SpaceAccessible,
    sign_language: SignLanguage,
  };
  return components[svgIconComponent];
};

const ButtonLink = ({
  url,
  text,
  target,
  color,
  holderStyle,
  svgIconComponent,
  iconOnRight,
  svgHeight,
  svgWidth,
  svgColor,
}) => {
  const unique = generateRandomHex();
  const hasIcon = svgIconComponent ? true : false;
  const MyIcon = svgIconComponent ? IconComponent(svgIconComponent) : null;
  console.log(hasIcon, MyIcon)
  const iconcolor = svgColor ? svgColor : "#FFF";

  const btnColor = color ? color : "#00873e";

  const hoverColors = {
    "#00873e": "rgb(0, 102, 49)",
    "#FAC112": "#F4BD12",
    "#d63f31": "rgb(187, 50, 37)",
  };

  const hoverColor = hoverColors[color]

  return (
    <>
      <style>
        {btnCSS
          .replace(/BTNUNIQUE/gm, unique)
          .replace(/BTNHOVERCOLOR/gm, hoverColor)
          .replace(/BTNCOLOR/gm, btnColor)}
        {hasIcon &&
          btnCSVCSS
            .replace(/BTNCOLOR/gm, btnColor)
            .replace(/BTNUNIQUE/gm, unique)
            .replace("SVGHEIGHT", svgHeight)
            .replace("SVGWIDTH", svgWidth)
            .replace("SVGCOLOR", iconcolor)}
      </style>
      <div style={holderStyle}>
        <a
          className={`btn-${unique}`}
          aria-disabled="false"
          target={target}
          href={url}
        >
          {hasIcon && !iconOnRight && (
            <div className={`btn-${unique}-icon`}>
              <MyIcon />
            </div>
          )}
          <span className="whitespace-nowrap">{text}</span>
          {hasIcon && iconOnRight && (
            <div className={`btn-${unique}-icon`}>
              <MyIcon />
            </div>
          )}
        </a>
      </div>
    </>
  );
};

const SvgIconLink = ({
  url,
  text,
  svgIcon,
  style,
  svgColor,
  svgWidth,
  svgHeight,
  iconOnRight,
  holderStyle,
  svgIconComponent,
}) => {
  const unique = generateRandomHex();
  const MyIcon = IconComponent(svgIconComponent);
  const getIcon = () => {
    return (
      <>
        {svgIconComponent !== "other" && svgIconComponent !== "" && (
          <div className={`svg-${unique}-icon`}>
            <MyIcon />
          </div>
        )}
        {svgIconComponent === "other" && (
          <div className={`svg-${unique}-icon`}>
            <MyIcon svgIcon={svgIcon} />
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <style>
        {svgCSS
          .replace(/BTNUNIQUE/gm, unique)
          .replace("SVGHEIGHT", svgHeight)
          .replace("SVGWIDTH", svgWidth)
          .replace("SVGCOLOR", svgColor)}
      </style>
      <div className={`svg-${unique}-icon-link-holder`} style={holderStyle}>
        <>
          {!iconOnRight && getIcon()}

          <div className={`svg-${unique}-link`}>
            <a href={url} style={style} target="_blank" rel="noreferrer">
              {text}
            </a>
          </div>

          {iconOnRight && getIcon()}
        </>
      </div>
    </>
  );
};

const SvgIconText = ({
  text,
  svgIcon,
  style,
  svgColor,
  svgWidth,
  svgHeight,
  iconOnRight,
  holderStyle,
  svgIconComponent,
}) => {
  const unique = generateRandomHex();
  const MyIcon = IconComponent(svgIconComponent);
  const getIcon = () => {
    return (
      <>
        {svgIconComponent !== "other" && svgIconComponent !== "" && (
          <div className={`svg-${unique}-icon`}>
            <MyIcon />
          </div>
        )}
        {svgIconComponent === "other" && (
          <div className={`svg-${unique}-icon`} >
            <MyIcon svgIcon={svgIcon} />
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <style>
        {svgTextCSS
          .replace(/BTNUNIQUE/gm, unique)
          .replace("SVGHEIGHT", svgHeight)
          .replace("SVGWIDTH", svgWidth)
          .replace("SVGCOLOR", svgColor)}
      </style>
      <div className={`svg-${unique}-icon-text-holder`} style={holderStyle}>
        <>
          {!iconOnRight && getIcon()}

          <div className={`svg-${unique}-text`} style={style}>
            {text}
          </div>

          {iconOnRight && getIcon()}
        </>
      </div>
    </>
  );
};


const CustomTag = ({
  tag,
  text,
  style = {},
  url,
  newWindow,
  icon,
  alt,
  svgIcon,
  useSvgIcon,
  svgColor,
  svgWidth,
  svgHeight,
  svgIconComponent,
  iconOnRight,
}) => {
  const MyTag = tag;
  const target = newWindow ? "_blank" : "_self";

  const linkStyle = {
    color: style?.color,
    fontWeight: style?.fontWeight,
    fontStyle: style?.fontStyle,
    textDecoration: style?.textDecoration,
  };
  const holderStyle = {
    marginTop: style.marginTop,
    marginBottom: style.marginBottom,
  };

  const Link = () => (
    <a href={url} target={target} style={linkStyle}>
      {text}
    </a>
  );

  if (tag === "a") {
    return Link();
  } else if (tag === "icon-text") {
    return (
      <SvgIconText
        text={text}
        svgIcon={svgIcon.replace(/(\r\n|\n|\r|\t)/gm, "")}
        alt={alt}
        style={linkStyle}
        holderStyle={holderStyle}
        iconOnRight={iconOnRight}
        svgColor={svgColor}
        svgWidth={svgWidth}
        svgHeight={svgHeight}
        svgIconComponent={svgIconComponent}
      />
    );
  } else if (tag === "jmj-button") {
    return (
      <ButtonLink
        text={text}
        url={url}
        color={style?.color}
        target={target}
        holderStyle={holderStyle}
        iconOnRight={iconOnRight}
        svgColor={svgColor}
        svgWidth={svgWidth}
        svgHeight={svgHeight}
        svgIconComponent={svgIconComponent}
      />
    );
  } else if (tag === "iconlink" && !useSvgIcon) {
    return (
      <DownloadLink
        text={text}
        url={url}
        icon={icon}
        alt={alt}
        style={linkStyle}
      ></DownloadLink>
    );
  } else if (tag === "iconlink" && useSvgIcon) {
    return (
      <SvgIconLink
        text={text}
        url={url}
        svgIcon={svgIcon.replace(/(\r\n|\n|\r|\t)/gm, "")}
        alt={alt}
        style={linkStyle}
        holderStyle={holderStyle}
        iconOnRight={iconOnRight}
        svgColor={svgColor}
        svgWidth={svgWidth}
        svgHeight={svgHeight}
        svgIconComponent={svgIconComponent}
      ></SvgIconLink>
    );
  }

  console.log("setting link");
  const content = url !== "" ? Link() : text;

  return <MyTag style={style}>{content}</MyTag>;
};

export default CustomTag;