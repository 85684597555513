import React, {useState, useEffect} from "react";
import Copy from "../../Copy";
import { Col, Container, Row, Form } from "react-bootstrap";
import { renderToStaticMarkup } from "react-dom/server";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import data from "./data";

const FeaturedTemplate = ({ items, locale, localesOrder }) => {
  const featured = [items.guides[locale], items.prayerBook[locale]]
  return (
    <div class="jmj-container">
      <div class="jmj-row">
        {featured.map((f) => (
          <div class="jmj-col">
            <div class="jmj-card">
              <div class="jmj-card-img">
                <a href={f.file} target="_blank">
                  <img
                    src={f.thumb}
                    alt={`${f.name} - ${data.languages[locale]}`}
                  />
                </a>
              </div>
              <h5 class="jmj-card-title">
                {f.name}
                <br />
                <span>{data.languages[locale]}</span>
              </h5>
              <div class="jmj-card-links">
                <a href={f.file} target="_blank" class="jmj-btn">
                  {data.buttons[locale]}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const Template = ({ items, locale, localesOrder }) => {
    console.log(items, locale, localesOrder);
  return (
    <div class="jmj-container">
      <div class="jmj-row">
        {localesOrder.map((loc) => (
          <div class="jmj-col">
            <div class="jmj-card">
              <div class="jmj-card-img">
                <a href={items[loc].file} target="_blank" >
                  <img
                    src={items[loc].thumb}
                    alt={`${items[loc].name} - ${data.languages[loc]}`}
                  />
                </a>
              </div>
              <h5 class="jmj-card-title">
                {items[loc].name}
                <br />
                <span>{data.languages[loc]}</span>
              </h5>
              <div class="jmj-card-links">
                <a href={items[loc].file} target="_blank" class="jmj-btn">
                  {data.buttons[locale]}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

function Guides() {
  const [styles, setStyles] = useState("");
  const [preparedDataFeat, setPreparedDataFeat] = useState({});
  const [preparedData, setPreparedData] = useState({});
  const [preparedDataPrayerBook, setPreparedDataPrayerBook] = useState({});
  const channels = ["jmj", "alameda", "comercio", "loures"];
  const languages = ["pt", "en", "es", "fr", "it"];

  const getData = () => {
    return languages.map((l) => {});
  };


  const getHTML = (items, localesOrder, locale) => {
    console.log(styles);
    const htmlString = renderToStaticMarkup(
      <Template items={items} localesOrder={localesOrder} locale={locale} />
    );
    return htmlString;
  };

  const getHTMLFeatured = (items, localesOrder, locale) => {
    console.log(styles);
    const htmlString = renderToStaticMarkup(
      <FeaturedTemplate
        items={items}
        localesOrder={localesOrder}
        locale={locale}
      />
    );
    return htmlString;
  };

  const moveToFirst = (arr, item) => {
    console.log(arr)
    const new_array = [...arr];
    const foundIdx = new_array.findIndex((el) => el == item);
    new_array.splice(foundIdx, 1);
    new_array.unshift(item);
    console.log(new_array)
    return new_array
  }

  const pluck = (arr, item) => {
    console.log(arr)
    const new_array = [...arr];
    const foundIdx = new_array.findIndex((el) => el == item);
    new_array.splice(foundIdx, 1);
    return new_array
  }

  const prepareItems = () => {
    const prepared = {}
    const preparedPrayer = {}
    const featured = {}
    languages.forEach(l => {
        const ordered = pluck(languages, l);
        prepared[l] = getHTML(data.guides, ordered, l);
    });
    languages.forEach(l => {
        const ordered = pluck(languages, l);
        featured[l] = getHTMLFeatured(data, ordered, l);
    });
    languages.forEach(l => {
        const ordered = pluck(languages, l);
        preparedPrayer[l] = getHTML(data.prayerBook, ordered, l);
    });

    setPreparedData(prepared);
    setPreparedDataFeat(featured);
    setPreparedDataPrayerBook(preparedPrayer);
  }

  
  useEffect(() => {
    fetch("/style-guides.css.txt")
      .then((r) => r.text())
      .then((cssString) => {
        setStyles("<style>" + cssString + "\r\n" + "</style>");
        const styleEl = document.createElement("style");
        styleEl.innerHTML = cssString + "\r\n";
        document.head.appendChild(styleEl);
        return () => {
          document.head.removeChild(styleEl);
        };
      });
    prepareItems();
  }, []);

  return (
    <Container>
      CSS: <Copy text={styles} />
      <Row>
        {languages.map((l) => (
          <>
            <Col>
              <h3>{l}</h3>
              <h5>Feat</h5>
              <Copy text={preparedDataFeat[l]} />
              <h5>Guia</h5>
              <Copy text={preparedData[l]} />

              <h5>Prayer</h5>
              <Copy text={preparedDataPrayerBook[l]} />
            </Col>
          </>
        ))}
      </Row>
      {languages.map((l) => (
        <>
          <h3>{l}</h3>
          <div dangerouslySetInnerHTML={{ __html: preparedDataFeat[l] }}></div>
          <div dangerouslySetInnerHTML={{ __html: preparedData[l] }}></div>
          <div
            dangerouslySetInnerHTML={{ __html: preparedDataPrayerBook[l] }}
          ></div>
        </>
      ))}
    </Container>
  );
}

export default Guides;
