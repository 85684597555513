import React, { useEffect, useState } from "react";
import CSVReader from "../../CsvReader";
import Copy from "../../Copy";
import Preview from "./Preview";
import { Col, Container, Row, Form } from "react-bootstrap";
import { renderToStaticMarkup } from "react-dom/server";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import categoriesDefinitions from "./categories";

import DayTemplate from "./template";

const LOCALES = ["pt", "en", "it", "fr", "es"];
function Festival() {
  const [preparedData, setPreparedData] = useState({});
  const [preparedDataHTML, setPreparedDataHTML] = useState({});
  const [inputCategories, setInputCategories] = useState([]);
  const [styles, setStyles] = useState("");
  const [locale, setLocale] = useState("pt");
  const [copyClicks, setCopyClicks] = useState({});
  const [currentClickOnLoc, setCurrentClickOnLoc] = useState();

  const categoriesIcons = categoriesDefinitions
    .map((c) => {
      if (c.class === "") {
        return "";
      }
      return `.category-icon.${c.class} { background-image: url(${c.icon}); }`;
    })
    .join("\r\n");

  useEffect(() => {
    fetch("/style.raw.txt")
      .then((r) => r.text())
      .then((cssString) => {
        console.log(categoriesIcons);
        setStyles(
          "<style>" + cssString + "\r\n" + categoriesIcons + "</style>"
        );
        const styleEl = document.createElement("style");
        styleEl.innerHTML = cssString + "\r\n" + categoriesIcons;
        document.head.appendChild(styleEl);
        return () => {
          document.head.removeChild(styleEl);
        };
      });
  }, [categoriesIcons]);

  const compareTimes = (a, b) => {
    const [hour1, minute1] = a["Start Time"].split(":");
    const [hour2, minute2] = b["Start Time"].split(":");
    if (parseInt(hour1, 10) < parseInt(hour2, 10)) {
      return -1;
    } else if (parseInt(hour1, 10) > parseInt(hour2, 10)) {
      return 1;
    } else {
      // If the hours are the same, compare minutes
      if (parseInt(minute1, 10) < parseInt(minute2, 10)) {
        return -1;
      } else if (parseInt(minute1, 10) > parseInt(minute2, 10)) {
        return 1;
      } else {
        return 0; // Times are equal
      }
    }
  };

  const compareDates = (a, b) => {
    const [day1, month1, year1] = a.split("/");
    const [day2, month2, year2] = b.split("/");

    // Create Date objects for the two dates
    const date1 = new Date(`${year1}-${month1}-${day1}`);
    const date2 = new Date(`${year2}-${month2}-${day2}`);

    if (date1 < date2) {
      return -1; // dateString1 is earlier
    } else if (date1 > date2) {
      return 1; // dateString2 is earlier
    } else {
      return 0; // Both dates are equal
    }
  };

  const prepareData = (data) => {
    setPreparedData({});
    const clicks = {};
    const schedule = {};
    const fileCategories = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      const day = item.Day;

      if (day !== "") {
        const category = item["Session Catergory"];

        if (typeof schedule[day] === "undefined") {
          schedule[day] = {
            date: day,
            categories: {},
          };
        }
        if (typeof schedule[day].categories[category] === "undefined") {
          schedule[day].categories[category] = {
            category: category,
            items: [],
          };
        }

        schedule[day].categories[category].items.push(item);
        schedule[day].categories[category].items =
          schedule[day].categories[category].items.sort(compareTimes);
        if (!fileCategories.includes(category)) {
          fileCategories.push(category);
        }
      }
    }

    const keys = Object.keys(schedule);
    LOCALES.forEach((loc) => {
      clicks[loc] = {};
      keys.forEach((key) => {
        clicks[loc][key] = false;
      });
    });

    setCopyClicks(clicks);
    setCurrentClickOnLoc("");
    setInputCategories(fileCategories);
    setPreparedData(schedule);
  };

  const getMissingCategories = () => {
    return inputCategories
      .map((c) => {
        let found = false;
        for (let i = 0; i < categoriesDefinitions.length; i++) {
          if (categoriesDefinitions[i].category === c) {
            found = true;
          }
        }
        if (!found) {
          return c;
        } else {
          return false;
        }
      })
      .filter((e) => e !== false);
  };

  const getHTML = (data, language = null) => {
    console.log(styles);
    const lang = language || locale;
    const htmlString = renderToStaticMarkup(
      <DayTemplate item={data} locale={lang} />
    );
    return htmlString;
  };

  const copiedText = (loc, key) => {
    setCurrentClickOnLoc(loc);
    setCopyClicks(() => {
      const clicksLoc = copyClicks[loc];
      return {
        ...copyClicks,
        [loc]: {
          ...clicksLoc,
          [key]: true,
        },
      };
    });
  };

  useEffect(() => {
    const keys = Object.keys(preparedData);
    const htmlData = {};
    LOCALES.forEach((loc) => {
      htmlData[loc] = {};
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        htmlData[loc][key] = getHTML(preparedData[key], loc);
      }
    });
    setPreparedDataHTML(htmlData);
  }, [preparedData]);

  return (
    <div className="App">
      <Container className="mt-5 mb-5">
        <Row>
          <Col>
            <CSVReader
              onUploadAccepted={(results) => {
                prepareData(results.data);
              }}
            ></CSVReader>
          </Col>
          <Col>
            <Form.Select
              value={locale}
              onChange={(e) => setLocale(e.target.value)}
              aria-label="Default select example"
            >
              <option value="pt">pt</option>
              <option value="en">en</option>
              <option value="it">it</option>
              <option value="fr">fr</option>
              <option value="es">es</option>
            </Form.Select>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="mb-3">
          Categories Missing:
          {getMissingCategories().join(" | ")}
        </Row>
        <Row>
          <Col>
            CSS: <Copy text={styles} />
          </Col>
        </Row>
        <Row className="mt-2 copy-buttons">
          {LOCALES.map((loc) => (
            <Col className={{ active: currentClickOnLoc === loc }}>
              <h3>{loc}</h3>
              {Object.keys(preparedData)
                .sort(compareDates)
                .map((key) => (
                  <div className={`mt-3 p-3 ${copyClicks[loc][key] ? "clicked" : ""}`}>
                    <div >
                      {preparedData[key].date}{" "}
                      <Copy
                        onClick={() => copiedText(loc, key)}
                        text={preparedDataHTML[loc][key]}
                        locale={locale}
                      />
                    </div>
                  </div>
                ))}
            </Col>
          ))}
        </Row>

        {Object.keys(preparedData)
          .sort(compareDates)
          .map((key) => (
            <div className="mt-5">
              {key}
              <div>Dia {preparedData[key].date}</div>
              <Preview data={preparedData[key]} locale={locale} />
            </div>
          ))}
      </Container>
    </div>
  );
}

export default Festival;
