import React from 'react';
import { Button } from 'react-bootstrap';

import { useCSVReader } from 'react-papaparse';

const styles = {
  csvReader: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  browseFile: {
    width: '20%',
  },
  acceptedFile: {
    border: '1px solid #ccc',
    height: 45,
    lineHeight: 2.5,
    paddingLeft: 10,
    width: '60%',
    overflow: 'hidden',
  },
  remove: {
    borderRadius: 0,
    padding: '0 20px',
  },
  progressBarBackgroundColor: {
    backgroundColor: 'red',
  },
};
const CSVReader = ({onUploadAccepted, useHeaders = true}) => {
  const { CSVReader } = useCSVReader();

  return (
    <CSVReader
      onUploadAccepted={onUploadAccepted}
      config={{ header: useHeaders }}
    >
      {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }) => (
        <>
          <div style={styles.csvReader}>
            <Button type="button" {...getRootProps()} style={styles.browseFile}>
              Browse file
            </Button>
            <div style={styles.acceptedFile}>
              {acceptedFile && acceptedFile.name}
            </div>
            <Button
              variant="secondary"
              {...getRemoveFileProps()}
              style={styles.remove}
            >
              Remove
            </Button>
          </div>
          <ProgressBar style={styles.progressBarBackgroundColor} />
        </>
      )}
    </CSVReader>
  );
}
export default CSVReader;