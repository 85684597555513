import { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCopy, faCircleCheck} from '@fortawesome/free-regular-svg-icons';

const Copy = ({text, label="Copy", onClick = () => {}}) => {
  const [icon, setIcon] = useState(faCopy);
  const [variant, setVariant] = useState("primary");
  const copyText = () => {
    navigator.clipboard.writeText(text);
    setIcon(faCircleCheck);
    setVariant("success");
    setTimeout(() => {
      setIcon(faCopy)
      setVariant("primary");
    }, 5000)
    onClick()
  }
  return (
    <Button size="" variant={variant} onClick={copyText}>
      {label}{" "}
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
};

export default Copy;
