import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Root from './pages/Root';
import Festival from './pages/festival/festival';
import Typography from './pages/typography/typography';
import SecurityForces from './pages/security-forces/index';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Partituras from './pages/partituras/partituras';
import CaloriesTable from './pages/calories/index';
import Restaurants from './pages/restaurants/index';
import Guides from './pages/guide/index';
import Transmissions from './pages/transmissions/index';
import Riseup from './pages/riseup/index';
const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "festival",
        element: <Festival />,
      },
      {
        path: "partituras",
        element: <Partituras />,
      },
      {
        path: "",
        element: <Typography />,
      },
      {
        path: "tipografia",
        element: <Typography />,
      },
      {
        path: "seguranca",
        element: <SecurityForces />,
      },
      {
        path: "calories",
        element: <CaloriesTable />,
      },
      {
        path: "restaurantes",
        element: <Restaurants />,
      }, 
      {
        path: "riseup",
        element: <Riseup />,
      }, 
      {
        path: "guias",
        element: <Guides />,
      },
      {
        path: "transmissoes",
        element: <Transmissions />,
      },
    ],
  },
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
